<template>
  <div class="v-stack gap-3 h-stretch">
    <a class="heading-title-2">Crew</a>
    <div class="h-stack gap-3 h-start">
      <div style="align-self: center">Show Pay:</div>
      <input type="checkbox" v-model="showPay" />
    </div>
    <div class="table">
      <div class="table-header user-row">
        <div class="text-left">Name</div>
        <div class="text-left" v-if="showPay">Pay</div>
        <div v-if="!showPay"></div>
        <div></div>
      </div>
      <div v-for="user in users" :key="user._id" class="table-row user-row">
        <div class="text-left" @click="$router.push('/users/detail/' + user._id)">
          {{ user.fullName }}
        </div>
        <div v-if="!showPay"></div>
        <div v-if="showPay" class="text-left" @click="$router.push('/users/detail/' + user._id)">
          {{ getPay(user) }}
        </div>
        <div class="h-stack gap-3">
          <button @click="$router.push('/users/bonus/new/' + user._id)" class="add">
            Bonus
          </button>
          <button @click="$router.push('/users/advance/new/' + user._id)" class="add">
            Advance
          </button>
          <button @click="$router.push('/users/fine/new/' + user._id)" class="add">
            Fine
          </button>
          <button class="add" @click="$router.push('/users/workday/new/' + user._id)">
            Workday
          </button>
          <button class="add" @click="$router.push('/users/pay/new/' + user._id)">
            Pay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utils from "@/utils.js";
const moment = require("moment");

export default {
  data() {
    return {
      users: [],
      showPay: false,
      format: utils.format,
    };
  },
  methods: {
    ...mapActions(["getUsers"]),
    getPay(user) {
      const date = moment();
      let selectedPay = null;
      for (const pay of user.pay) {
        const payDate = moment(pay.date);

        if (payDate.isSameOrBefore(date, "day")) {
          if (selectedPay == null) {
            selectedPay = pay;
          } else {
            const selectedPayDate = moment(selectedPay.date);
            if (selectedPayDate.isBefore(payDate, "day")) {
              selectedPay = pay;
            }
          }
        }
      }

      if (selectedPay) {
        return (
          Math.round(selectedPay.amount) +
          " Kč/hod (" +
          this.format.currency(
            Math.round((selectedPay.amount * 8 * 252) / 12 / 1000) * 1000
          ) +
          "Kč/měsíc)"
        );
      }

      return "0";
    },
  },
  mounted() {
    this.getUsers().then((users) => {
      this.users = users
        .filter((user) => user.accessLevel >= 5)
        .sort((a, b) => {
          return a.fullName.localeCompare(b.fullName, "cs");
        });
    }).catch((error) => {
      console.log(error)
    });
  },
};
</script>

<style lang="scss" scoped>
.user-row {
  grid-auto-columns: 1fr 1fr 550px !important;
  gap: 10px;
}

.user-row button {
  color: white;
}
</style>
